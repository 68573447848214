<template>
  <div>
    <section class="pt-125 md:pt-175 lg:pt-200 mb-100">
      <div class="container">
        <div class="max-w-screen-xl mx-auto">
          <breadcrumb :breadcrumbs="[data?.entry].filter((b) => b)" />
        </div>
      </div>
    </section>

    <wysiwyg
      v-if="data?.entry?.text"
      :text="data.entry.text"
    />

    <content-blocks :content-blocks="data?.entry?.contentBlocks" />
  </div>
</template>

<script setup>
import {
  useRoute,
  useI18n,
  useAsyncGql,
  useSeo,
  ref,
  watch,
} from '#imports';

// Data
const route = useRoute();
const { locale } = useI18n({ useScope: 'global' });
const variables = ref({
  site: locale.value,
  uri: route.path.substring(1).replace(/\/+$/, ''),
});
const { data } = await useAsyncGql('content', variables.value);

// Watcher
watch(data, (value) => {
  if (value?.entry?.seomatic) {
    useSeo(value.entry.seomatic);
  }
}, { immediate: true });
</script>
